@font-face {
    font-family: "Raleway";
    src: url("../../fonts/Raleway-VariableFont_wght.ttf");
}
.template-container{
    background: linear-gradient(to bottom, #3c3c3c 0%, #1a1a1a 50%, #3c3c3c 100%);
    color: white;
}
header{
.header{
    max-width: 1200px;
    height: 100px;
    margin: 0 auto;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
        width: fit-content;
        height: fit-content;
        text-align: center;
        #logo-header{
            height: 65px;
        }
    }
    nav{
        width: 35%;
        ul{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            li{
                margin-right: 50px;
                color: white;
                font-family: "Raleway";
                font-size: 1.3rem;
                font-weight: 500;
                cursor: pointer;
                text-decoration: none;
                transition: 0.06s;
                &:hover{
                    border-bottom: 3px solid #00ff75;
                }
                .link{
                    text-decoration: none;
                    color: white;
                }
                .active{
                    border-bottom: 3px solid #00ff75;
                }
            }

        }
    }

    .log-in-btn{
        font-family: 'Raleway';
        text-decoration: none;
        width: 100px;
        height: 25px;
        text-align: center;
        padding: 10px;
        background-color: #00ff778d;
        color: white;
        border-radius: 10px;
        font-size: 1.3rem;
        font-weight: 500;
        transition: all 0.3s;
        &:hover{
            cursor: pointer;
            background-color: #00ff77d7;
            transform: scale(1.03);
        }
    }

    .welcome-msg{
        font-size: 1.4rem;
        font-weight: 500;
        color: #00ff75;
    }

    .btn-burger-menu{
        display: none;
        z-index: 1;
        .burger_menu_close{
            width: 40px !important;
            height: 40px !important;
        }
        img{
            width: 40px;
            height: 40px;
        }
    }
    .btn-responsive{
        display: none;
        img{
            width: 35px;
            height: 35px;
        }
    }
    .btn{
        display: none;
        width: fit-content;
        height: 25px;
        padding: 7px;
        text-align: center;
        color: white;
        font-family: "Raleway";
        font-weight: 700;
        font-size: 1.1rem;
        padding-top: 15px;
        background-color: #00ff7771;
        text-decoration: none;
        border-radius: 7px;
        transition: 0.3s;
        &:hover{
            background-color: white;
            color: #00ff75;
            transform: scale(1.05);
            
        }
    }
}
}
footer{
    padding-top: 170px;
.footer1, .footer2{
    font-family: "Raleway";
    width: 1200px;
    height: 100px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info-responsive{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 50%;
        .info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .link{
            color: white;
            text-decoration: none;
            margin: 15px;
            transition: 0.3s;
            &:hover{
                transform: scale(1.07);
                color: #00ff75;
            }
        }
    }
    }
    
    .social{
        display: flex;
        align-items: center;
        justify-content: space-around;
        display: none;
        .img{
            margin: 10px;
            transition: 0.5s;
            &:hover{
                cursor: pointer;
                transform: scale(1.1);
                background-color: #00ff75;
                border-radius: 40%;
            }
        }

    }
}
}

.footer2{
    height: 50px;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 65px;
    #logo-footer{
        margin: 0;
        height: 70px;
    }
    div{
        color: white;
        font-size: 1.2rem;
    }
}

@media (max-width: 1225px){
    header{
        width: 100%;
        .header{
            width: 90%;
            padding-right: 0;
            padding-left: 0;
        }
    }
    footer{
        width: 100%;
        .footer1, .footer2{
            width: 80%;
        }
    }
}





@media (max-width: 820px){
    header{
        width: 100%;
        .header{
            width: 90%;
            padding-right: 0;
            padding-left: 0;
            div{
                width: 30%;
                #logo-header{
                    width: 85%;
                }
            }
            nav{
                width: 65%;
            }
            .btn{
                display: none;
            }
            .btn-responsive{
                display: none;
            }
        }
    }
}

@media (max-width: 778px){
    header{
        width: 100%;
        .header{
            width: 90%;
            padding-right: 0;
            padding-left: 0;
            div{
                width: fit-content;
            }
            nav{
                display: none;
            }
            .btn{
                display: none;
            }
            .btn-responsive{
                display: none;
            }
            .btn-burger-menu{
                display: initial;
                order: 1;
            }
            #logo{
                order: 2;
            }
        }
    }
}

@media (max-width: 808px){
    footer{
        width: 100%;
        .footer1, .footer2{
            width: 80%;
            .info-responsive{
                width: 65%;
            }
        }
    }
}
@media (max-width: 620px){
    footer{
        width: 100%;
        .footer1, .footer2{
            width: 80%;
            .info-responsive{
                width: 70%;
            }
        }
    }
}


@media (max-width: 565px){
    footer{
        width: 100%;
        margin: 0 auto;
        padding-bottom: 60px;
        .footer1, .footer2{
            width: 80%;
            justify-content: space-between;
            height: fit-content;
            .info-responsive{
                flex-direction: column;
                align-items: center;
                width: 100%;
                .info{
                    align-items: center;
                }
            }
            .social{
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    .footer2{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        #logo-footer{
            margin-top: 180px;
            mask-border: 70px;
            padding: 50px;
        }
        div{
            color: white;
            font-size: 1.2rem;
        }
    }
}
