.movie-details-page{
    color: white;
    font-family: 'Raleway';
    .movie-details-container{
        max-width: 1200px;
        margin: 0px auto;
        padding: 10px;
        .back-arrows-container{
            display: flex;
            .back-arrow-2{
                width: 37px !important;
            }
            .back-arrow, .back-arrow-2{
                width: 45px;
                height: 40px;
                margin-bottom: 45px;
                transition: all 0.0001s;
                &.active{
                    visibility: hidden;
                    z-index: 1;
                    order: 2;
                }
                &.not-active{
                    visibility: visible;
                    z-index: 2;
                    order: 1;
                }
                &:hover{
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }
        }
        .title{
            font-size: xx-large;
            font-weight: 500;
            border-bottom: 2px solid #22ff00b4;
            padding: 3.66px;
            margin-bottom: 20px;
        }
        .movie-infos{
            display: flex;
            align-items: flex-start;
            width: 1000px;
            .movie-poster{
                width: 30%;
                height: 450px;
            }
            .movie-details{
                width: 65%;
                margin-left: 30px;
                .details{
                    margin-bottom: 30px;
                    line-height: 1.5;
                    .detail-title{
                        font-size: 1.2rem;
                        font-weight: 500;
                        margin-right: 10px;
                    }
                }
                .trailer-youtube-link{
                    text-decoration: none;
                    .watch-trailer{
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 200px;
                        height: 57px;
                        padding: 7px;
                        text-align: center;
                        color: white;
                        font-family: "Raleway";
                        font-weight: 700;
                        font-size: 1.1rem;
                        background-color: #22ff00b4;
                        text-decoration: none;
                        border: none;
                        transition: 0.3s;
                        &:hover{
                            cursor: pointer;
                            background-color: #14e34b;
                            transform: scale(1.03);
                        }
                        img{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
        .spinner-container{
            display: flex;
            justify-content: center; // Horizontally center
            align-items: center;     // Vertically center
            height: 30vh;           // Take the full viewport height
            width: 100%;             // Take the full width
        }
    }
    @media (max-width: 1070px){
        .movie-details-container{
            width: 90%;
            .title{
                width: 90%;
            }
            .movie-infos{
                width: 90%;
                padding-right: 15px;
                .movie-poster{
                    width: 35%;
                }
            }
        }
    }
    
    @media (max-width: 780px){
        .movie-details-container{
            width: 90%;
            // padding: 50px;
            .title{
                width: 90%;
            }
            .movie-infos{
                width: 90%;
                padding-right: 15px;
                .movie-poster{
                    height: 350px;
                    width: 50%;
                }
            }
        }
    }
    @media (max-width: 660px){
        .movie-details-container{
            width: 90%;
            // padding: 40px;
            .title{
                width: 90%;
            }
            .movie-infos{
                width: 90%;
                padding-right: 15px;
                .movie-poster{
                    height: 350px;
                    width: 300px;
                }
            }
        }
    }
    @media (max-width: 550px){
        .movie-details-container{
            width: 90%;
            // padding: 10px;
            .title{
                width: 90%;
            }
            .movie-infos{
                flex-direction: column;
                align-items: flex-start;
                width: 90%;
                padding-right: 15px;
                .movie-poster{
                    height: 350px;
                    width: 60%;
                }
                .movie-details{
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 15px;
                    .details{
                        line-height: 1.5;
                        font-size: 0.9rem;
                        .detail-title{
                            font-size: 1rem;
                            margin-right: 5px;
                        }
                    }
            }
        }
    }

}

@media (max-width: 480px){
    .movie-details-container{
        width: 90%;
        // padding: 10px;
        .title{
            width: 90%;
        }
        .movie-infos{
            flex-direction: column;
            align-items: flex-start;
            width: 90%;
            padding-right: 15px;
            .movie-poster{
                height: 350px;
                width: 70%;
            }
            .movie-details{
                width: 100%;
                margin-left: 0px;
                margin-top: 15px;
                .details{
                    line-height: 1.5;
                    font-size: 0.9rem;
                    .detail-title{
                        font-size: 1rem;
                        margin-right: 5px;
                    }
                }
        }
    }
}

}

@media (max-width: 350px){
    .movie-details-container{
        width: 90%;
        // padding: 10px;
        .title{
            width: 90%;
        }
        .movie-infos{
            flex-direction: column;
            align-items: flex-start;
            width: 90%;
            padding-right: 15px;
            .movie-poster{
                height: 350px;
                width: 100%;
            }
            .movie-details{
                width: 100%;
                margin-left: 0px;
                margin-top: 15px;
                .details{
                    line-height: 1.5;
                    font-size: 0.9rem;
                    .detail-title{
                        font-size: 1rem;
                        margin-right: 5px;
                    }
                }
        }
    }
}

}

}