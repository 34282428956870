.home{
    font-family: "Raleway";
    .main{
        max-width: 1200px;
        margin: 0 auto;
        height: fit-content;
        .intro{
            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
                font-size: 1.7rem;
                color: white;
                margin-top: 60px;
                margin-bottom: 50px;
            }
            .search-bar{
                display: flex;
                align-items: center;
                margin-bottom: 40px;
                .search-icon{
                    width: 30px;
                    padding: 6.6px;
                    height: 30px;
                    border-radius: 20px 0 0 20px;
                    background-color: rgb(255, 255, 255);
                }
                .search-input{
                background-color: rgb(255, 255, 255);
                width: 400px;
                height: 30px;
                border: 2px solid white;
                padding: 5px;
                padding-left: 10px;
                border-radius: 0 20px 20px 0;
                outline: none;
                font-size: 1.1rem;
                &:focus{
                    outline-color: white;
                }
            }
        }
    }
    }.movies, .series{
        width: 1200px;
        margin: 0 auto;
        h2{
            font-size: 1.8rem;
            font-weight: 600;
            color: white;
            margin-top: 100px;
            margin-bottom: 20px;
        }
        .content-container{
            height: fit-content;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 auto;
        }
        .spinner-container{
            display: flex;
            justify-content: center; // Horizontally center
            align-items: center;     // Vertically center
            height: 30vh;           // Take the full viewport height
            width: 100%;             // Take the full width
        }
    }
    .series{
        padding-bottom: 100px;
        
    }

    @media (max-width: 1232px) {
        .main{
            max-width: 100%;
            .trending, .movies, .series{
                width: 85%; 

            }
        }
    }
    
    @media (max-width: 1089px) {
        .main{
            max-width: 100%;
            .trending, .movies, .series{
                width: 78%; 
            }
        }
    }
    @media (max-width: 933px) {
        .main{
            max-width: 100%;
            .trending, .movies, .series{
                width: 83%; 
            }
        }
    }
    @media (max-width: 897px) {
        .main{
            max-width: 100%;
            .trending, .movies, .series{
                width: 87%; 
            }
        }
    }

    @media (max-width: 837px) {
        .main{
            max-width: 100%;
            .trending, .movies, .series{
                width: 80%;
                h2{
                    padding-left: 15px;
                }
                .content-container{
                    padding: 15px;
                }                
            }
        }
    }
    @media (max-width: 750px) {
        .main{
            max-width: 100%;
            .trending, .movies, .series{
                width: 95%;
                h2{
                    padding-left: 15px;
                }
                .content-container{
                    padding: 15px;
                }                
            }
        }
    }
    @media (max-width: 651px) {
        .main{
            max-width: 100%;
            .trending, .movies, .series{
                width: 90%;
                h2{
                    padding-left: 15px;
                }
                .content-container{
                    padding: 15px;
                }                
            }
        }
    }
    @media (max-width: 615px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
            }
            .trending, .movies, .series{
                width: 95%;
            }
        }
    }
   
    @media (max-width: 480px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
            }
            .trending, .movies, .series{
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                }
            }
        }
    }
    @media (max-width: 442px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
            }
            .trending, .movies, .series{
                width: 95%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                }
            }
        }
    }

    @media (max-width: 400px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
            }
            .trending, .movies, .series{
                width: 95%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                    width: 90%;
                    text-align: center;
                }
            }
        }
    }
    @media (max-width: 350px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
            }
            .trending, .movies, .series{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                    width: 90%;
                    text-align: center;
                }
            }
        }
    }
    
}

