.contact-form{
    font-family: "Raleway";
    .contact-form-container{
        width: 1200px;
        margin:  auto;
        padding: 100px;
        .main-grid{
            .form{
                color: white !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                width: 700px;
                height: fit-content;
                margin: 20px auto;
                padding: 10px;
                border-radius: 20px;
                .input, .text{
                    margin: 15px;
                    padding: 10px;
                    width: 80%;
                    height: 30px;
                    outline: none !important;
                    font-size: 1.1rem;
                    border-top: none !important;
                    border-left: none !important;
                    border-right: none !important;
                    color: rgb(255, 255, 255) !important;
                    border-color: white !important;
                    background: none;
                    &:focus{
                        outline-color: #00ff75 !important;
                        border-color: #00ff75 !important;
                    }
                    &::placeholder {  
                        color: white !important;
                    }
                }
                .text{
                    height: 100px;
                }
                label{
                    font-size: 1.2rem;
                    font-weight: normal;
                    display: none;
                }
                .btn{
                    width: 150px;
                    height: 50px;
                    margin: 20px;
                    text-align: center;
                    color: #00ff75;
                    font-family: "Raleway";
                    font-weight: 600;
                    font-size: 1.1rem;
                    background-color: rgb(255, 255, 255);
                    border: none;
                    border-radius: 10px;
                    text-decoration: none;
                    transition: 0.3s;
                    &:hover{
                        transform: scale(1.05);
                        cursor: pointer;
                    }
                }
                .validation-message{
                    width: 90%;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-left: 5px;
                    padding-right: 5px;
                    font-size: 1.2rem;
                    margin: 15px auto;
                    text-align: center;
                    &.success {
                        background-color: #00ff7770;
                      }
                    
                      &.error {
                        background-color: red;
                      }
                }
            }
        }
    }
}

@media(max-width: 1400px){
    .contact-form{
        width: 100%;
        .contact-form-container{
            width: 80%;
            padding: 0;
            .main-grid{
                width: 100%;
                .form{
                    margin: 0 auto;
                }
            }
        }
    }
    }
    @media(max-width: 1000px){
        .contact-form{
            width: 100%;
            .contact-form-container{
                width: 100%;
                padding: 0;
                .main-grid{
                    width: 100%;
                    padding: 0;
                    .form{
                        margin: 0 auto;
                        .input, .text{
                            width: 80%;
                        }
                    }
                }
            }
        }
        }
        @media(max-width: 800px){
            .contact-form{
                width: 100%;
                .contact-form-container{
                    width: 80%;
                    padding: 0;
                    .main-grid{
                        width: 100%;
                        padding: 0;
                        .form{
                            margin: 0 auto;
                            width: 90%;
                            .input, .text{
                                width: 80%;
                            }
                        }
                    }
                }
            }
            }