.series{
    font-family: "Raleway";
    .main{
        width: 1200px;
        margin: 0 auto;
        height: fit-content;
        .intro{
            display: flex;
            flex-direction: column;
            align-items: center;

            .categories-container{
                width: 100%;
                height: fit-content;
                margin-top: 50px;
                margin-bottom: 50px;
                .display-categories{
                    margin-left: 0;
                    width: 100px;
                    height: 35px;
                    background-color: #00ff48;
                    color: #2c2c2c;
                    font-size: 1.1rem;
                    font-weight: 700;
                    text-decoration: none;
                    padding: 10px;
                    border-radius: 8px;
                    transition: all 0.3s;
                    &:hover{
                        cursor: pointer;
                        background-color: #00ff48ae;
                    }
            }
            }

            .title{
                font-size: 1.7rem;
                color: white;
                margin-top: 60px;
                margin-bottom: 50px;
            }
            .search-bar{
                width: 45%;
                display: flex;
                align-items: center;
                margin-bottom: 40px;
                background-color: rgb(255, 255, 255);
                border-radius: 20px; /* This will give the search-bar a rounded appearance */
                overflow: hidden; /* This ensures inner contents respect the border-radius */
                .search-icon{
                    width: 30px;
                    padding: 6.6px;
                    height: 30px;
                }
                .search-input{
                    flex-grow: 1; /* This will make the input take up remaining space */
                    border: none; /* Remove border */
                    padding: 5px;
                    padding-left: 10px; /* Make space for the search icon */
                    background-color: transparent; /* Make input's background transparent */
                    font-size: 1.1rem;
                    outline: none;
                &:focus{
                    box-shadow: 0 0 5px white; /* Adjust as desired */
                }
            }
        }
            .description{
                width: 900px;
                margin: 0 auto;
                padding: 30px;
                background-color: #00ff7766;
                text-align: center;
                color: white;
                font-size: 1.1rem;
                line-height: 1.2;
            }
            
        }
        .spinner-container{
            display: flex;
            justify-content: center; // Horizontally center
            align-items: center;     // Vertically center
            height: 30vh;           // Take the full viewport height
            width: 100%;             // Take the full width
        }
    }
    .series{
        width: 1200px;
        margin: 0 auto;
        h2{
            font-size: 1.8rem;
            font-weight: 600;
            color: white;
            margin-top: 100px;
            margin-bottom: 20px;
        }
        .content-container{
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 auto;
        }
        
    }
    .series{
        padding-bottom: 100px;
    }


    @media (max-width: 1232px) {
        .main{
            max-width: 100%;
            .categories-container{
                width: 85% !important;
            }
            .trending, .movies, .series{
                width: 85%; 
            }
        }
    }
    
    @media (max-width: 1089px) {
        .main{
            max-width: 100%;
            .categories-container{
                width: 78% !important;
            }
            .trending, .movies, .series{
                width: 78%; 
            }
        }
    }
    @media (max-width: 933px) {
        .main{
            max-width: 100%;
            .categories-container{
                width: 83% !important;
            }
            .trending, .movies, .series{
                width: 83%; 
            }
        }
    }
    @media (max-width: 897px) {
        .main{
            max-width: 100%;
            .categories-container{
                width: 87% !important;
            }
            .trending, .movies, .series{
                width: 87%; 
            }
        }
    }

    @media (max-width: 837px) {
        .main{
            max-width: 100%;
            .categories-container{
                width: 80% !important;
            }
            .trending, .movies, .series{
                width: 80%;
                h2{
                    padding-left: 15px;
                }
                .content-container{
                    padding: 15px;
                }                
            }
        }
    }
    @media (max-width: 750px) {
        .main{
            max-width: 100%;
            .categories-container{
                width: 95% !important;
            }
            .trending, .movies, .series{
                width: 95%;
                h2{
                    padding-left: 15px;
                }
                .content-container{
                    padding: 15px;
                }                
            }
        }
    }
    @media (max-width: 651px) {
        .main{
            max-width: 100%;
            .categories-container{
                width: 90% !important;
            }
            .trending, .movies, .series{
                width: 90%;
                h2{
                    padding-left: 15px;
                }
                .content-container{
                    padding: 15px;
                }                
            }
        }
    }
    @media (max-width: 615px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
                .categories-container{
                    width: 80% !important;
                }
            }
            .trending, .movies, .series{
                width: 95%;
            }
        }
    }
   
    @media (max-width: 480px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
                .categories-container{
                    width: 80% !important;
                }
            }
            .trending, .movies, .series{
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                }
            }
        }
    }
    @media (max-width: 442px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
                .categories-container{
                    width: 95% !important;
                }
            }
            .trending, .movies, .series{
                width: 95%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                }
            }
        }
    }

    @media (max-width: 400px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
                .categories-container{
                    width: 95% !important;
                }
            }
            .trending, .movies, .series{
                width: 95%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                    width: 90%;
                    text-align: center;
                }
            }
        }
    }
    @media (max-width: 350px) {
        .main{
            max-width: 100%;
            .intro{
                width: 100%;
                .title{
                    width: 80%;
                    text-align: center;
                }
                .search-bar{
                    width: 80%;
                }
                .categories-container{
                    width: 90% !important;
                }
            }
            .trending, .movies, .series{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h2{
                    padding: 0;
                    width: 90%;
                    text-align: center;
                }
            }
        }
    }
}
