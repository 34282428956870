.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Raleway';
    margin-bottom: 15px;
    width: 185px;
    height: 350px;
    .poster-image{
        width: 100%;
        height: 95%;
        object-fit: cover;
        transition: all 0.3s;
        &:hover{
            cursor: pointer;
            opacity: 0.6;
        }
    }
    .info{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 10px;
        padding: 6px 0px;
        background-color: rgba(0, 0, 0, 0.419);
        color: white;
    }

    
}
@media (max-width: 1000px) {
        .card{
            width: 175px;
            height: 340px;
            .poster-image{
                width: 175px;
                height: 95%;
            }
            .info{
                padding: 3px;
                width: 95%;
            }
        }
    }
    @media (max-width: 900px) {
        .card{
            width: 180px;
            height: 330px;
            .poster-image{
                width: 180px;
                height: 95%;
            }
            .info{
                padding: 3px;
                width: 95%;
            }
        }
    }
    @media (max-width: 837px) {
        .card{
            width: 190px;
            height: 330px;
            .poster-image{
                width: 190px;
                height: 95%;
            }
            .info{
                padding: 3px;
                width: 95%;
            }
        }
    }
    @media (max-width: 655px) {
        .card{
            width: 170px;
            height: 330px;
            .poster-image{
                width: 170px;
                height: 95%;
            }
            .info{
                padding: 3px;
                width: 95%;
                font-size: 0.9rem;
            }
        }
    }


    @media (max-width: 532px) {
        .card{
            width: 175px;
            height: 300px;
            .poster-image{
                width: 175px;
                height: 95%;
            }
            .info{
                padding: 3px;
                width: 95%;
                font-size: 0.9rem;
            }
        }
    }
    @media (max-width: 474px) {
        .card{
            width: 160px;
            height: 280px;
            .poster-image{
                width: 160px;
                height: 95%;
            }
            .info{
                padding: 3px;
                width: 95%;
                font-size: 0.8rem;
                margin-top: 2px;
                margin-bottom: 5px;
            }
        }
    }
  
   
    @media (max-width: 370px) {
        .card{
            width: 150px;
            height: 200px;
            margin: auto;
            padding: 2px;
            padding-bottom: 40px;
            .poster-image{
                width: 100%;
                object-fit: fill;
                height: 95%;
            }
            .info{
                width: 95%;
                padding: 3px;
                margin-top: 2px;
                margin-bottom: 25px;
            }
        }
    }




