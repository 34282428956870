.catetgories-container{
    position: absolute;
    top: 290px;
    left: 25%;
    z-index: 1;
    width: 50% !important;
    height: 270px !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: space-around !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    padding: 20px;
    font-family: "Raleway";
    background-color: #00ff48;
    box-shadow: 5px 5px 15px 1px rgba(0,0,0,0.82);
    border-radius: 20px;
    .category{
        margin: 10px;
        font-size: 1.1rem;
        font-weight: 600;
        color: black;
        transition: all 0.2s;
        &:hover{
            cursor: pointer;
            color: rgb(0, 0, 0);
            background-color: white;
            transform: scale(1.05);
        }
    }
}

@media (max-width: 1050px){
    .catetgories-container{
        height: 300px !important;
    }
}
@media (max-width: 900px){
    .catetgories-container{
        height: 350px !important;
        position: initial !important;
        margin-top: 20px !important;
        width: 90% !important;
    }
}
@media (max-width: 778px){
    .catetgories-container{
        height: 490px !important;
        position: initial !important;
        margin-top: 20px !important;
        width: 90% !important;

    }
}
@media (max-width: 740px){
    .catetgories-container{
        height: 490px !important;
        position: initial !important;
        margin-top: 20px !important;
        width: 90% !important;
    }
}
@media (max-width: 545px){
    .catetgories-container{
        height: fit-content !important;
        width: 95% !important;
        position: initial !important;
        margin-top: 20px !important;
        flex-wrap: wrap !important;
        flex-direction: row !important;
        justify-content: flex-start !important;

        .category{
            margin-bottom: 35px !important;
            margin-right: 25px !important;
        }
    }
}
@media (max-width: 490px){
    .catetgories-container{
        height: fit-content !important;
        width: 80% !important;
        position: initial !important;
        margin-top: 20px !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        flex-wrap: wrap !important;
        .category{
            margin-bottom: 30px !important;
            margin-right: 25px !important;

        }
    }
}