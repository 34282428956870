.privacy-policy{
    border: 1px solid rgba(255, 255, 255, 0.491);
    padding: 50px;
    .first-part{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        line-height: 2;
        margin-bottom: 50px;
        h1{
            font-size: 1.2rem;
            font-weight: bold;
            text-decoration: underline;
        }
    }
    .title{
        font-size: 1.1rem;
        font-weight: bold;
        text-decoration: underline 1px;
        margin-bottom: 20px;
    }
    div{
        margin-bottom: 30px;
    }

    .date{
        margin-top: 100px;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}