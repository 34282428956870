.responsive-menu{
    position: absolute;
    top: 0px;
    left: 0;
    width: 85% !important;
    height: 100vh !important;
    margin: auto;
    padding-top: 120px;
    padding-left: 20px;
    height: fit-content;
    background-color: #00ff77;
    font-family: "Raleway";
    transform: translateX(-100%);
    transition: all 0.4s ease-in-out;
    &.active{
        transform: translateX(0);
    }
    .menu-items{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
        list-style: none;
        #categories{
            margin: 10px;
            text-decoration: none;
            color: #2c2c2c;
            font-size: 1.2rem;
            font-weight: 600;
            text-align: start;
            &:hover{
                cursor: pointer;
            }
        }
        .item{
            margin: 10px;
            .link{
                text-decoration: none;
                color: #2c2c2c;
                font-size: 1.2rem;
                font-weight: 600;
                width: 100%;
            }
        }
    }
}